<template>
  <div class="view">
    <div class="content">
      <LogoBanner/>
      <div class="wrapper">

        <b-img id="qrCode" src="~@/assets/qr.png" width="250"/>

        <button class="mt-3 btn-grey btn" @click="handleMail">
          <h6>An Email senden</h6>
        </button>



        <button class="mt-3 btn-grey btn" @click="handlePrint">
            <h6>Drucken</h6>
        </button>


        <button class="mt-3 btn-green btn" @click="$router.push('/qr')">
          <h5>
            Schließen
          </h5>
        </button>

      </div>
    </div>
  </div>
</template>

<script>
import LogoBanner from "../components/LogoBanner";
export default {
  name: "NewQRCodeCreated",
  components: {LogoBanner},
  methods: {
    handlePrint () {
      window.print()
    },
    handleMail () {
      location.href='mailto:?subject=Test%20Email&body=ABAC%20Code: https://doppelte-regelstudienzeit.de/img/qr.188e964c.png'
    }
  }
}
</script>

<style scoped>
h6 {
  margin: 0;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
